import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import Logo from '../images/DolarBlue_Logo.png'; // Importa la imagen
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function Header() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg')); // 1250px breakpoint

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuItems = [
        { text: 'Tipos de Cambio', href: '#exchange-rates-section' },
        { text: 'Calculadora', href: '#conversion-calculator-section' },
        { text: 'P2P', href: '#markets-section' },
        { text: 'Bancos', href: '#bank-limits-section' },
    ];

    return (
        <>
            <AppBar
                position="static"
                sx={{
                    background: '#2c3e50',
                    padding: '10px 0',
                    display: isMobile ? 'none' : 'block', // Oculta el header completo en pantallas pequeñas
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <img src={Logo} alt="Logo de Dólar Blue Bolivia" style={{ height: '50px', marginRight: '20px' }} />
                        </Box>
                        <Typography variant="h5" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: '#ecf0f1' }}>
                            COTIZACION DEL DOLAR BLUE EN BOLIVIA
                        </Typography>
                        <Button color="inherit" href="#exchange-rates-section" sx={{ marginRight: 2, color: '#ecf0f1' }}>Tipos de Cambio</Button>
                        <Button color="inherit" href="#conversion-calculator-section" sx={{ marginRight: 2, color: '#ecf0f1' }}>Calculadora</Button>
                        <Button color="inherit" href="#markets-section" sx={{ marginRight: 2, color: '#ecf0f1' }}>P2P</Button>
                        <Button color="inherit" href="#bank-limits-section" sx={{ color: '#ecf0f1' }}>Bancos</Button>
                    </Toolbar>
                </Container>
            </AppBar>

            <AppBar
                position="fixed"
                sx={{
                    background: '#2c3e50',
                    padding: '5px 0',
                    display: isMobile ? 'flex' : 'none', // Muestra solo la barra pequeña en pantallas pequeñas
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img src={Logo} alt="Logo de Dólar Blue Bolivia" style={{ height: '30px' }} />
                    </Toolbar>
                </Container>
            </AppBar>

            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 250, backgroundColor: '#34495e', height: '100%', color: '#ecf0f1' }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        {menuItems.map((item) => (
                            <ListItem button component="a" href={item.href} key={item.text}>
                                <ListItemText primary={item.text} sx={{ color: '#ecf0f1' }} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </>
    );
}

export default Header;
