import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Button, CardMedia } from '@mui/material';
import BinanceLogo from '../images/binance-logo.jpg'; // Importa la imagen del logo

const exchanges = [
    {
        name: "Binance",
        description: "Compra USDT en Binance, uno de los mercados de criptomonedas más grandes del mundo.",
        logo: BinanceLogo, // Asigna el logo importado
        link: "https://p2p.binance.com/trade/all-payments/USDT?fiat=BOB",
        color: "#F3BA2F"
    },
    {
        name: "Airtm",
        description: "Compra USDC en Airtm, una plataforma popular para el intercambio de divisas y criptomonedas.",
        link: "https://www.airtm.com",
        logo: "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
        color: "#28A7ED"
    },
];

const P2PExchangesSection = () => {
    return (
        <Box id="markets-section" sx={{ 
            backgroundColor: '#f9f9f9', 
            borderRadius: '12px', 
            padding: '40px', 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
            margin: 'auto',
            textAlign: 'center',
            maxWidth: '1200px',
            marginTop: '40px',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.15)',
            },
            '@media (max-width: 1000px)': {
                maxWidth: '65%', // Limita el ancho total de la sección a un 65% si la pantalla es menor a 1000px
            }
        }}>
            <Typography variant="h4" component="h2" sx={{ 
                fontWeight: 'bold', 
                marginBottom: '30px', 
                textTransform: 'uppercase', 
                color: '#34495e',
                borderBottom: '2px solid #34495e',
                display: 'inline-block',
                paddingBottom: '10px',
                '@media (max-width: 1000px)': {
                    fontSize: '1.4rem', // Reduce el tamaño de la fuente en pantallas menores a 1000px
                }
            }}>
                Mercados para Comprar USDT/USDC
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {exchanges.map((exchange, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <Card sx={{ 
                            backgroundColor: '#ffffff', 
                            borderRadius: '12px', 
                            color: '#333',
                            border: `2px solid ${exchange.color}`,
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: `0px 8px 20px ${exchange.color}`,
                            }
                        }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={exchange.logo}
                                alt={`${exchange.name} logo`}
                                sx={{ 
                                    objectFit: 'contain', 
                                    padding: '20px', 
                                    backgroundColor: exchange.color,
                                    '@media (max-width: 1000px)': {
                                        height: '120px', // Ajusta suavemente la altura del logo en pantallas menores a 1000px
                                        padding: '10px', // Ajusta el padding para dar más espacio al contenido
                                    },
                                    '& img': {
                                        maxHeight: '80%', // Reduce el tamaño del icono a un 80%
                                        maxWidth: '80%', // Asegura que el icono no exceda el espacio asignado
                                        margin: 'auto', // Centra el icono en el contenedor
                                    }
                                }}
                            />
                            <CardContent sx={{ textAlign: 'left' }}>
                                <Typography variant="h6" component="h3" sx={{ 
                                    fontWeight: 'bold', 
                                    marginBottom: '10px',
                                    color: '#333',
                                    '@media (max-width: 1000px)': {
                                        fontSize: '0.9rem', // Ajusta el tamaño de la fuente en pantallas menores a 1000px
                                    }
                                }}>
                                    {exchange.name}
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ 
                                    marginBottom: '20px',
                                    color: '#555',
                                    '@media (max-width: 1000px)': {
                                        fontSize: '0.8rem', // Ajusta suavemente el tamaño de la fuente del texto en pantallas menores a 1000px
                                    }
                                }}>
                                    {exchange.description}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: '10px' }}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        href={exchange.link} 
                                        target="_blank" 
                                        sx={{
                                            backgroundColor: exchange.color,
                                            color: '#fff',
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0',
                                                color: exchange.color,
                                            },
                                            '@media (max-width: 1000px)': {
                                                padding: '8px 16px', // Ajusta el tamaño del botón en pantallas menores a 1000px
                                                fontSize: '0.8rem', // Ajusta suavemente el tamaño de la fuente del botón en pantallas menores a 1000px
                                            }
                                        }}>
                                        Ir al sitio
                                    </Button>
                                    {/* Botón adicional solo para Binance */}
                                    {exchange.name === 'Binance' && (
                                        <Button 
                                            variant="outlined" 
                                            href="/uso_binance" 
                                            sx={{
                                                borderColor: exchange.color,
                                                color: exchange.color,
                                                '&:hover': {
                                                    backgroundColor: exchange.color,
                                                    color: '#fff',
                                                },
                                                '@media (max-width: 1000px)': {
                                                    padding: '8px 16px', // Ajusta el tamaño del botón en pantallas menores a 1000px
                                                    fontSize: '0.8rem', // Ajusta suavemente el tamaño de la fuente del botón en pantallas menores a 1000px
                                                }
                                            }}>
                                            Aprende más sobre Binance
                                        </Button>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default P2PExchangesSection;
