import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';

function RateBox({ title, buyRate, sellRate, description, date }) {
    return (
        <Box
            sx={{
                backgroundColor: '#fff',
                color: '#2c3e50',
                borderRadius: '12px',
                padding: '20px',
                textAlign: 'center',
                minWidth: '280px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.03)',
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
                },
            }}
        >
            <Typography
                variant="h6"
                component="div"
                sx={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    marginBottom: '4px',
                    color: title.includes('Blue') ? '#2980b9' : '#1abc9c'
                }}>
                {title}
            </Typography>

            <Typography
                variant="h5"
                component="div"
                sx={{
                    fontWeight: 'bold',
                    margin: '8px 0',
                    color: '#c0392b'
                }}>
                Compra: Bs {buyRate}
            </Typography>
            <Typography
                variant="h5"
                component="div"
                sx={{
                    fontWeight: 'bold',
                    margin: '8px 0',
                    color: '#27ae60'
                }}>
                Venta: Bs {sellRate}
            </Typography>
            <Typography variant="body2" component="div" sx={{ color: '#34495e' }}>
                {description}
            </Typography>
            <Typography
                variant="caption"
                component="div"
                sx={{
                    marginTop: '8px',
                    display: 'block',
                    color: '#7f8c8d'
                }}>
                Última actualización: {date}
            </Typography>
        </Box>
    );
}

function ExchangeRatesSection() {
    const [rates, setRates] = useState({ blue: { buy: 0, sell: 0 }, official: { buy: 0, sell: 0 } });
    const [lastUpdate, setLastUpdate] = useState('');

    useEffect(() => {
        fetch('https://www.dolarbluebolivia.click/api/dolar_blue')
            .then(response => response.json())
            .then(data => {
                setRates(data);
                const now = new Date();
                setLastUpdate(`${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}, ${now.toLocaleTimeString()}`);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <section id="exchange-rates-section" style={{ padding: '30px 20px', backgroundColor: '#f2f2f2' }}>
            <Typography
                variant="h5"
                component="h2"
                sx={{
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    color: '#2c3e50',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    marginTop: {
                        xs: '80px', // Para pantallas pequeñas (por debajo de 600px)
                        sm: '60px', // Para pantallas medianas (600px a 899px)
                        md: '100px', // Para pantallas medianas (900px a 1200px)
                        lg: '20px', // Para pantallas grandes (mayores a 1200px)
                    },
                    position: 'relative',
                }}>
                Tipos de Cambio
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                <Grid item>
                    <RateBox
                        title="Boliviano Blue"
                        buyRate={rates.blue.buy}
                        sellRate={rates.blue.sell}
                        description="Dólar Blue - Boliviano"
                        date={lastUpdate}
                    />
                </Grid>
                <Grid item>
                    <RateBox
                        title="Boliviano Oficial"
                        buyRate={rates.official.buy}
                        sellRate={rates.official.sell}
                        description="Dólar Oficial - Boliviano"
                        date={lastUpdate}
                    />
                </Grid>
            </Grid>
        </section>
    );
}

export default ExchangeRatesSection;
