import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Tooltip } from '@mui/material';
import BNBLogo from '../images/BNB.jpg';
import MercantilLogo from '../images/Banco-Mercantil.jpg';
import BisaLogo from '../images/BANCO BISA.jpg';
import UnionLogo from '../images/UNION.jpeg';
import BCPLogo from '../images/BCP.png';
import SolLogo from '../images/BancoSol.png';
import GanaderoLogo from '../images/Banco Ganadero.jpg';
import EconomicoLogo from '../images/BancoEconomico.png';
import FortalezaLogo from '../images/FortalezaLogo.jpeg';

const BankLimitsSection = () => {
    const banks = [
        {
            name: "Banco Nacional de Bolivia",
            limit: "$50 / Semanal",
            tarifario: "https://www.bnb.com.bo/PortalBNB/Documentos/Tarifario/TarifarioNormaSCLIE.pdf?v78",
            logo: BNBLogo,
            comments: "Alibaba esta restringido"
        },
        {
            name: "Banco Mercantil Santa Cruz",
            limit: "$100 / Quincenal",
            tarifario: "https://www.bmsc.com.bo/AdditionalInfo/tariffs",
            logo: MercantilLogo,
            comments: "Límite de $25 para PayPal / semanal"
        },
        {
            name: "Banco Bisa",
            limit: "$100 / Quincenal",
            tarifario: "https://www.bisa.com/",
            logo: BisaLogo,
            comments: "Solo aplica para clientes antiguos, 6 meses de antigüedad para clientes nuevos"
        },
        {
            name: "Banco Unión",
            limit: "$50 / Quincenal",
            tarifario: "https://bancounion.com.bo/PDF/TasasTarifario/Trifario_Servicios-2024_05.pdf?width=800&height=800&iframe=true",
            logo: UnionLogo,
            comments: "Clientes que cuenten con Tarjetas de Débito emitidas a partir del 01 de junio de 2024 tiene un límite de USD 30"
        },
        {
            name: "Banco de Credito de Bolivia",
            limit: "$30 / Semanal",
            tarifario: "https://www.bcp.com.bo/Personas/CanalesAtencion/BancaInternet",
            logo: BCPLogo,
            comments: "Sin comentarios adicionales"
        },
        {
            name: "Banco Sol",
            limit: "$100 / Mensual",
            tarifario: "https://www.bancosol.com.bo/",
            logo: SolLogo,
            comments: "Solo para clientes con antigüedad mayor a 6 meses"
        },
        {
            name: "Banco Ganadero",
            limit: "$75 / Quincenal",
            tarifario: "https://prodbgwebportal.blob.core.windows.net/assets/pdf-tarifario.pdf",
            logo: GanaderoLogo,
            comments: "Sin comentarios adicionales"
        },
        {
            name: "Banco Economico",
            limit: "$34 / Mensual",
            tarifario: "https://www.baneco.com.bo/tarifario",
            logo: EconomicoLogo,
            comments: "Sin comentarios adicionales"
        },

        {
            name: "Banco Fortaleza",
            limit: "$50 / Cada 3 dias",
            tarifario: "https://www.bancofortaleza.com.bo/wp-content/uploads/2024/08/TARIFARIO-BFO-26-08-2024.pdf",
            logo: FortalezaLogo,
            comments: "Sin comentarios adicionales"
        },
    ];

    return (
        <Box id="bank-limits-section" sx={{ 
            backgroundColor: '#fff', 
            borderRadius: '12px', 
            padding: '40px', 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
            margin: 'auto',
            textAlign: 'center',
            maxWidth: '1200px',
            marginTop: '40px',
            transition: 'all 0.3s ease-in-out',
            width: '80%', // Limitar el contenedor al 80% del ancho de la pantalla
            '@media (min-width: 1000px)': { // Aumentar al 100% cuando la pantalla es mayor a 1000px
                maxWidth: '1200px',
                width: '100%',
            },
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.15)',
            }
        }}>
            <Typography variant="h4" component="h2"
                sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',  
                    marginBottom: '30px',
                    color: '#2c3e50', 
                    textTransform: 'uppercase',
                    letterSpacing: '1.5px',
                    borderBottom: '3px solid #2c3e50',
                    paddingBottom: '10px',
                    '@media (max-width: 1000px)': {  
                        fontSize: '18px',
                        marginBottom: '20px',
                    }
                }}>
                Límites de Compra en Dólares para Bancos en Bolivia con tarjetas de Debito
                 || Actualizado 13/09/2024
            </Typography>
            <TableContainer component={Paper} sx={{ 
                width: '100%',  // Aumentar el contenedor de la tabla al 100% del ancho disponible
                margin: 'auto', 
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
                borderRadius: '12px',
                overflow: 'hidden',
            }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: '#2c3e50', fontWeight: 'bold', '@media (max-width: 1000px)': { fontSize: '12px', padding: '8px' } }}>Banco</TableCell>
                            <TableCell sx={{ color: '#2c3e50', fontWeight: 'bold', '@media (max-width: 1000px)': { fontSize: '12px', padding: '8px' } }}>Límite de Compra en USD</TableCell>
                            <TableCell sx={{ color: '#2c3e50', fontWeight: 'bold', '@media (max-width: 1000px)': { fontSize: '12px', padding: '8px' } }}>Tarifario</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {banks.map((bank, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ padding: '10px', '@media (max-width: 1000px)': { padding: '5px' } }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={bank.logo} alt={`${bank.name} Logo`} style={{ height: '40px', marginRight: '15px', '@media (max-width: 1000px)': { height: '30px', marginRight: '10px' } }} />
                                        <Typography variant="body1" sx={{ color: '#34495e', '@media (max-width: 1000px)': { fontSize: '14px' } }}>{bank.name}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ color: '#34495e', padding: '10px', '@media (max-width: 1000px)': { padding: '5px', fontSize: '14px' } }}>
                                    <Tooltip title={bank.comments || "Sin comentarios adicionales"}>
                                        <span>{bank.limit}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell sx={{ padding: '10px', '@media (max-width: 1000px)': { padding: '5px', fontSize: '12px' } }}>
                                    <Link href={bank.tarifario} target="_blank"
                                        sx={{
                                            color: '#2c3e50',
                                            textDecoration: 'none',
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                            '@media (max-width: 1000px)': { fontSize: '12px' }
                                        }}>
                                        Ver Tarifario
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default BankLimitsSection;
