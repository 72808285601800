import React, { useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';

const ConversionCalculator = () => {
    const [inputUsdToBob, setInputUsdToBob] = useState(1);
    const [inputBobToUsd, setInputBobToUsd] = useState(1);
    const [resultUsdToBob, setResultUsdToBob] = useState(0);
    const [resultBobToUsd, setResultBobToUsd] = useState(0);
    const [exchangeRate, setExchangeRate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://www.dolarbluebolivia.click/api/dolar_blue')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Error al obtener los datos de la API');
                }
                return response.json();
            })
            .then((data) => {
                setExchangeRate(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error al obtener los datos:', error);
                setError(error.message);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (exchangeRate) {
            setResultUsdToBob((inputUsdToBob * exchangeRate.blue.buy).toFixed(2));
            setResultBobToUsd((inputBobToUsd / exchangeRate.blue.sell).toFixed(2));
        }
    }, [inputUsdToBob, inputBobToUsd, exchangeRate]);

    if (loading) {
        return <p>Cargando...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <Box
            id="conversion-calculator-section" 
            sx={{ 
            backgroundColor: '#fff', 
            borderRadius: '16px', 
            padding: '40px', 
            boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)', 
            maxWidth: '800px', 
            margin: 'auto',
            textAlign: 'center',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.2)',
            },
            '@media (max-width: 1000px)': {
                maxWidth: '60%', // Limita el ancho total de la sección a un 60% si la pantalla es menor a 1000px
            }
        }}>
            <Typography variant="h4" component="h2" sx={{ 
                fontWeight: 'bold', 
                marginBottom: '40px', 
                textTransform: 'uppercase', 
                color: '#333',
                '@media (max-width: 700px)': {
                    fontSize: '1.2rem', // Reduce el tamaño de la fuente al 80% cuando la pantalla es menor a 700px
                }
            }}>
                Calculadora de Conversión
            </Typography>
            <Grid container spacing={6} justifyContent="center">
                <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                        padding: '30px', 
                        backgroundColor: '#f5f5f5', 
                        borderRadius: '12px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                            backgroundColor: '#e0f7fa',
                        },
                        '&:after': {
                            content: '""',
                            display: 'block',
                            width: '100%',
                            height: '5px',
                            backgroundColor: '#16a085',
                            borderRadius: '0 0 12px 12px',
                            marginTop: '20px',
                        },
                        '@media (max-width: 1000px)': {
                            padding: '10px', // Reduce la altura de la caja cuando la pantalla es menor a 1000px
                        }
                    }}>
                        <Typography variant="h6" sx={{ 
                            fontWeight: 'bold', 
                            marginBottom: '20px',
                            '@media (max-width: 700px)': {
                                fontSize: '1rem', // Reduce el tamaño de la fuente al 80% cuando la pantalla es menor a 700px
                                marginBottom: '10px',
                            }
                        }}>
                            USD a BOB
                        </Typography>
                        <Typography variant="h6" sx={{ 
                            fontWeight: 'bold', 
                            marginBottom: '20px',
                            '@media (max-width: 700px)': {
                                fontSize: '1rem', // Reduce el tamaño de la fuente al 80% cuando la pantalla es menor a 700px
                                marginBottom: '10px',
                            }
                        }}>
                            1 USD = {exchangeRate.blue.buy.toFixed(2)} BOB
                        </Typography>
                        <TextField
                            label="USD"
                            variant="outlined"
                            value={inputUsdToBob}
                            onChange={(e) => setInputUsdToBob(e.target.value)}
                            sx={{
                                width: '100%', 
                                textAlign: 'center', 
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#f0f0f0',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                        <Typography variant="h6" sx={{ 
                            fontWeight: 'bold', 
                            marginTop: '20px', 
                            color: '#16a085',
                            '@media (max-width: 700px)': {
                                fontSize: '0.8rem', // Reduce el tamaño de la fuente al 80% cuando la pantalla es menor a 700px
                            }
                        }}>
                            {resultUsdToBob} BOB
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                        padding: '30px', 
                        backgroundColor: '#e0f7fa', 
                        borderRadius: '12px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                            backgroundColor: '#b2ebf2',
                        },
                        '&:after': {
                            content: '""',
                            display: 'block',
                            width: '100%',
                            height: '5px',
                            backgroundColor: '#2980b9',
                            borderRadius: '0 0 12px 12px',
                            marginTop: '20px',
                        },
                        '@media (max-width: 1000px)': {
                            padding: '10px', // Reduce la altura de la caja cuando la pantalla es menor a 1000px
                        }
                    }}>
                        <Typography variant="h6" sx={{ 
                            fontWeight: 'bold', 
                            marginBottom: '20px',
                            '@media (max-width: 700px)': {
                                fontSize: '0.8rem', // Reduce el tamaño de la fuente al 80% cuando la pantalla es menor a 700px
                            }
                        }}>
                            BOB a USD
                        </Typography>
                        <Typography variant="h6" sx={{ 
                            fontWeight: 'bold', 
                            marginBottom: '20px',
                            '@media (max-width: 700px)': {
                                fontSize: '0.8rem', // Reduce el tamaño de la fuente al 80% cuando la pantalla es menor a 700px
                            }
                        }}>
                            1 BOB = {(1 / exchangeRate.blue.sell).toFixed(2)} USD
                        </Typography>
                        <TextField
                            label="BOB"
                            variant="outlined"
                            value={inputBobToUsd}
                            onChange={(e) => setInputBobToUsd(e.target.value)}
                            sx={{
                                width: '100%', 
                                textAlign: 'center', 
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#f0f0f0',
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                        <Typography variant="h6" sx={{ 
                            fontWeight: 'bold', 
                            marginTop: '20px', 
                            color: '#2980b9',
                            '@media (max-width: 700px)': {
                                fontSize: '0.8rem', // Reduce el tamaño de la fuente al 80% cuando la pantalla es menor a 700px
                            }
                        }}>
                            {resultBobToUsd} USD
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ConversionCalculator;
