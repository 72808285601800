import React from 'react';
import Header from './components/Header';
import ExchangeRatesSection from './components/ExchangeRatesSection';
import CalculatorSection from './components/CalculatorSection';
import P2PExchangesSection from './components/P2PExchangesSection'; // Importa el componente P2PExchangesSection
import BankLimitsSection from './components/BankLimitsSection';
import ChartSection from './components/ChartSection';
import Footer from './components/Footer';
function App() {
    return (
        <>
            <Header />
            <ExchangeRatesSection />
            <ChartSection/>
            <CalculatorSection />
            <P2PExchangesSection />  {/* Agrega la sección de exchanges P2P */}
            <BankLimitsSection/>
            <Footer/>
        </>
    );
}

export default App;
