import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Box, Button, Grid } from '@mui/material';
import Papa from 'papaparse';

const ChartSection = () => {
    const [chartSeries, setChartSeries] = useState([
        { name: 'Compra', data: [] },
        { name: 'Venta', data: [] }
    ]);
    const [categories, setCategories] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState('day');
    const [buttonText, setButtonText] = useState('Descargar CSV');
    const [showTitle, setShowTitle] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setShowTitle(false);
            } else {
                setShowTitle(true);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vStWC7nikQErGZ0zcMbAbExgf7p1iPGB2xA55iH3kL02yOiRbb6UII9QfvrHhfmZMB0zQKWWpK0upE5/pub?output=csv';

        Papa.parse(csvUrl, {
            download: true,
            header: false,
            delimiter: ',',
            skipEmptyLines: true,
            complete: (result) => {
                const data = result.data;
                let relevantData = [];

                if (selectedPeriod === 'day') {
                    relevantData = data.slice(-96);
                } else if (selectedPeriod === 'week') {
                    const weekData = data.slice(-672); // Últimos 7 días con 96 puntos por día
                    relevantData = averageData(weekData, 14); // Tomamos el promedio de 14 puntos por día
                } else if (selectedPeriod === 'month') {
                    const monthData = data.slice(-2880); // Últimos 30 días con 96 puntos por día
                    relevantData = averageData(monthData, 32); // Tomamos el promedio de 32 puntos por día
                }

                const processedData = relevantData.map(entry => {
                    const [date, , , blueBuy, blueSell] = entry;
                    return {
                        date: date,
                        blueBuy: parseFloat(blueBuy.replace(',', '.')),
                        blueSell: parseFloat(blueSell.replace(',', '.')),
                    };
                });

                setCategories(processedData.map(entry => entry.date));
                setChartSeries([
                    { name: 'Compra', data: processedData.map(entry => entry.blueBuy) },
                    { name: 'Venta', data: processedData.map(entry => entry.blueSell) }
                ]);
            },
            error: (error) => {
                console.error('Error al procesar el CSV:', error);
            }
        });
    }, [selectedPeriod]);

    // Función para promediar los datos
    const averageData = (data, groupSize) => {
        const averagedData = [];

        for (let i = 0; i < data.length; i += groupSize) {
            const group = data.slice(i, i + groupSize);
            const avgBlueBuy = group.reduce((sum, entry) => sum + parseFloat(entry[3].replace(',', '.')), 0) / group.length;
            const avgBlueSell = group.reduce((sum, entry) => sum + parseFloat(entry[4].replace(',', '.')), 0) / group.length;
            averagedData.push([group[0][0], '', '', avgBlueBuy.toFixed(2), avgBlueSell.toFixed(2)]); // Usa la fecha del primer punto en el grupo
        }

        return averagedData;
    };

    const chartOptions = {
        chart: {
            id: 'chart1',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
            },
            toolbar: {
                show: true,
                tools: {
                    download: false, // Deja el botón de descarga desactivado
                }
            }
        },
        xaxis: {
            categories: categories,
            labels: {
                show: false, // Oculta las etiquetas en el eje x
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2, // Reduce el grosor de las líneas
        },
        title: {
            text: showTitle ? `Precio del Dólar Blue (Compra y Venta) - ${selectedPeriod.toUpperCase()}` : '',
            align: 'left',
        },
        colors: ['#FF4560', '#008FFB'], // Color para compra y venta
        tooltip: {
            x: {
                show: true,
                formatter: function (value, { dataPointIndex }) {
                    return categories[dataPointIndex]; // Mostrar la fecha correspondiente al índice
                }
            }
        }
    };

    const handleCsvDownload = () => {
        window.open('https://direct-link.net/1216154/csv-dolar-blue', '_blank');
    };

    return (
        <Box sx={{ padding: '20px', textAlign: 'center' }}>
            <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: '20px' }}>
                <Grid item>
                    <Button
                        variant={selectedPeriod === 'day' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedPeriod('day')}
                        sx={{
                            backgroundColor: selectedPeriod === 'day' ? '#4CAF50' : '#f0f0f0',
                            color: selectedPeriod === 'day' ? '#fff' : '#000',
                            '&:hover': {
                                backgroundColor: selectedPeriod === 'day' ? '#45a049' : '#d0d0d0',
                            },
                            padding: '10px 20px',
                            borderRadius: '20px',
                            fontWeight: 'bold'
                        }}
                    >
                        DÍA
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={selectedPeriod === 'week' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedPeriod('week')}
                        sx={{
                            backgroundColor: selectedPeriod === 'week' ? '#4CAF50' : '#f0f0f0',
                            color: selectedPeriod === 'week' ? '#fff' : '#000',
                            '&:hover': {
                                backgroundColor: selectedPeriod === 'week' ? '#45a049' : '#d0d0d0',
                            },
                            padding: '10px 20px',
                            borderRadius: '20px',
                            fontWeight: 'bold'
                        }}
                    >
                        SEMANA
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={selectedPeriod === 'month' ? 'contained' : 'outlined'}
                        onClick={() => setSelectedPeriod('month')}
                        sx={{
                            backgroundColor: selectedPeriod === 'month' ? '#4CAF50' : '#f0f0f0',
                            color: selectedPeriod === 'month' ? '#fff' : '#000',
                            '&:hover': {
                                backgroundColor: selectedPeriod === 'month' ? '#45a049' : '#d0d0d0',
                            },
                            padding: '10px 20px',
                            borderRadius: '20px',
                            fontWeight: 'bold'
                        }}
                    >
                        MES
                    </Button>
                </Grid>
            </Grid>
            <Box
                sx={{
                    width: '80%',
                    margin: '0 auto',
                    borderRadius: '20px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    overflow: 'hidden',
                    padding: '10px',
                    backgroundColor: '#fff',
                }}
            >
                <Chart options={chartOptions} series={chartSeries} type="line" height="350" />
            </Box>
            <Box sx={{ marginTop: '20px' }}>
                <Button
                    variant="outlined"
                    sx={{
                        backgroundColor: '#f0f0f0',
                        color: '#000',
                        padding: '10px 20px',
                        borderRadius: '20px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        transition: 'all 0.3s ease-in-out',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                        '&:hover': {
                            backgroundColor: '#4CAF50',
                            color: '#fff',
                            boxShadow: '0px 8px 20px rgba(0, 128, 0, 0.4)',
                            transform: 'scale(1.05)',
                        },
                        '&:active': {
                            boxShadow: '0px 2px 10px rgba(0, 128, 0, 0.2)',
                            transform: 'scale(1)',
                        },
                    }}
                    onClick={handleCsvDownload}
                    onMouseEnter={() => setButtonText('Tus anuncios ayudan a pagar el servidor')}
                    onMouseLeave={() => setButtonText('Descargar CSV')}
                >
                    {buttonText}
                </Button>
            </Box>
        </Box>
    );
}

export default ChartSection;
