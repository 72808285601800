import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
    return (
        <Box sx={{
            backgroundColor: '#2c3e50',
            color: '#ecf0f1',
            padding: '40px 0',
            textAlign: 'center',
            marginTop: '40px',
            boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.1)',
            '@media (max-width: 1250px)': {  // Media query para pantallas menores a 1250px
                padding: '20px 0',
                marginTop: '20px',
            }
        }}>
            <Container maxWidth="lg">
                <Typography variant="body1" sx={{
                    marginBottom: '15px',
                    fontWeight: 'bold',
                    '@media (max-width: 1250px)': {  // Media query para pantallas menores a 1250px
                        fontSize: '14px',
                    }
                }}>
                    &copy; 2024 Dólar Blue Bolivia. Todos los derechos reservados. Para el agente de la FELCC que pueda llegar a ver esto, el Dolar esta a 6.96Bs.
                </Typography>
                <Typography variant="body2" sx={{
                    marginBottom: '15px',
                    maxWidth: '800px',
                    margin: '0 auto',
                    '@media (max-width: 1250px)': {  // Media query para pantallas menores a 1250px
                        fontSize: '12px',
                        padding: '0 10px',  // Añade padding lateral para evitar que el texto se pegue a los bordes
                    }
                }}>
                    Este sitio web es únicamente informativo y no tiene la intención de influir en el mercado ni de realizar especulación alguna sobre el precio del dólar en Bolivia. Los datos aquí presentados se obtienen de fuentes públicas, incluyendo el mercado paralelo y plataformas de criptomonedas, y se actualizan para proporcionar la mayor precisión posible. Si tienes informacion que desees aportar, escribenos al correo:
                </Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px',
                    '@media (max-width: 1250px)': {  // Media query para pantallas menores a 1250px
                        flexDirection: 'column',
                        marginTop: '15px',
                    }
                }}>
                    <EmailIcon sx={{
                        color: '#1abc9c',
                        marginRight: '10px',
                        verticalAlign: 'middle',
                        '@media (max-width: 1250px)': {  // Media query para pantallas menores a 1250px
                            marginRight: '0',
                            marginBottom: '5px',
                        }
                    }} />
                    <Link href="mailto:CaptainDisrupt2208@protonmail.com" sx={{
                        color: '#1abc9c',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        '@media (max-width: 1250px)': {  // Media query para pantallas menores a 1250px
                            fontSize: '14px',
                        }
                    }}>
                        CaptainDisrupt2208@protonmail.com
                    </Link>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer;
